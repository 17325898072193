import React from "react";


const Option = ({ title, value }) => {
    return (
    <option value={value}>
      {title}
    </option>
  );

}

export default Option;