import { AUTH_URL } from "../config/api"

export const storeUser = async(cellphone, password) =>{ 
        console.log(cellphone, password)
        var data = {
            cellphone: cellphone,
            password: password,
        };
        var request_url = AUTH_URL + "/api/v1/user/create-account";
        const request_options = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
       const response = await fetch(request_url, request_options)
       const response_data = await response.json();

       if(!response.ok) throw new Error(data.message || "Something went wrong");

       return response_data
 
        
}