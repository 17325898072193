import { BACKEND_URL } from "../config/api"


export const createTask = async(data={}) => {
    var request_url = BACKEND_URL + "/api/v1/task/store";

    const request_options = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }
    const response = await fetch(request_url, request_options)
    const response_data = await response.json()

    if(!response.ok) throw new Error(data.message || "Something went wrong");

    return response_data
}