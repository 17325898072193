import React from "react"
import style from './welcome_page.module.css';
import NavBar from "../../partials/navbar/navbar";
import { Link } from "react-router-dom";
import CollapseText from "../../components/collapsable_text/collapse_text";
import plumber_botswana from "../../assets/images/jpg/plumber botswana.jpg";
import female_carpenter from '../../assets/images/png/female_carpenter.png';
import plumber_reparing_pipe from "../../assets/images/jpg/plumber-reparing-burst.jpg"
import Footer from "../../partials/footer/footer";
import ButtonCall from "../../components/button_call/button_call";



class WelcomePage extends React.Component {
    render() {
        return (
            <>
                <div>
                    <NavBar></NavBar>
                    <header className={style.header}>
                        <h1 className={style.header_h1}>Book Trusted Handyman For Home Tasks</h1>
                        <Link className={style.btn_get_started} to="/i-need-a-hero">Get Started</Link>
                        <div  className={style.service_icons}>
                            <div className={style.service_icons_col}>
                                <div className={style.service_icon}>
                                    <i className="bi bi-droplet"></i>
                                </div>
                                <div className={style.service_text}>
                                    <p>Plumbing</p>
                                </div>
                            </div>
                            <div className={style.service_icons_col}>
                                <div className={style.service_icon}>
                                    <i className="bi bi-house-door"></i>
                                </div>
                                <div className={style.service_text}>
                                    <p>Roofing</p>
                                </div>
                            </div>
                            <div className={style.service_icons_col}>
                                <div className={style.service_icon}>
                                    <i className="bi bi-plug"></i>
                                </div>
                                <div className={style.service_text}>
                                    <p>Electrical</p>
                                </div>
                            </div>
                            <div className={style.service_icons_col}>
                                <div className={style.service_icon}>
                                    <i className="bi bi-tv"></i>
                                </div>
                                <div className={style.service_text}>
                                    <p>TV Mounting</p>
                                </div>
                            </div>
                        </div>
                    </header>
                    <header className={style.mobile_header}>
                        <h1 className={style.header_h1}>We connect Homeowners to Handyman</h1>
                        <p className={style.sub_text}>We have verified Handyman ready to serve your home.</p>
                        <Link className={style.btn_get_started} to="/i-need-a-hero">Get Started</Link>
                        <div  className={style.service_icons}>
                            <div className={style.service_icons_col}>
                                <div className={style.service_icon}>
                                    <i className="bi bi-droplet"></i>
                                </div>
                                <div className={style.service_text}>
                                    <p>Plumbing</p>
                                </div>
                            </div>
                            <div className={style.service_icons_col}>
                                <div className={style.service_icon}>
                                    <i className="bi bi-house-door"></i>
                                </div>
                                <div className={style.service_text}>
                                    <p>Roofing</p>
                                </div>
                            </div>
                            <div className={style.service_icons_col}>
                                <div className={style.service_icon}>
                                    <i className="bi bi-plug"></i>
                                </div>
                                <div className={style.service_text}>
                                    <p>Electrical</p>
                                </div>
                            </div>
                            <div className={style.service_icons_col}>
                                <div className={style.service_icon}>
                                    <i className="bi bi-tv"></i>
                                </div>
                                <div className={style.service_text}>
                                    <p>TV Mounting</p>
                                </div>
                            </div>
                        </div>
                    </header>
                    <section className={style.section_value_propostion}>
                        <h4 className={style.section_title}>A platform for Home Improvement</h4>
                        <div className="container-fluid g-0">
                            <div className={"row g-4"}>
                                <div className={"col-xs-12 col-sm-12 col-md-6 col-lg-3"}>
                                    <div className={style.features_card}>
                                        <div className={style.features_card_icon}>
                                            <i className="bi bi-shield-check"></i>
                                        </div>
                                        <h6 className={style.features_card_title}>Trust & Safety</h6>
                                        <div className={style.features_card_text}>
                                            <p>We carefully vet and verify all our service providers, so you can invite them into your home with confidence.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-xs-12 col-sm-12 col-md-6 col-lg-3"}>
                                    <div className={style.features_card}>
                                        <div className={style.features_card_icon}>
                                            <i className="bi bi-star" id={style.star}></i>
                                        </div>
                                        <h6 className={style.features_card_title}>Quality Assurance</h6>
                                        <div className={style.features_card_text}>
                                            <p>Find professionals with the skills and expertise you need. Our detailed profiles and reviews help you make the right choice.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-xs-12 col-sm-12 col-md-6 col-lg-3"}>
                                    <div className={style.features_card}>
                                        <div className={style.features_card_icon}>
                                            <i className="bi bi-water"></i>
                                        </div>
                                        <h6 className={style.features_card_title}>Seamless Experience</h6>
                                        <div className={style.features_card_text}>
                                            <p>Booking home services has never been this easy. Our user-friendly platform ensures convenience at every step..</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-xs-12 col-sm-12 col-md-6 col-lg-3"}>
                                    <div className={style.features_card}>
                                        <div className={style.features_card_icon}>
                                            <i className="bi bi-briefcase"></i>
                                        </div>
                                        <h6 className={style.features_card_title}>Transparency</h6>
                                        <div className={style.features_card_text}>
                                            <p>No hidden costs or surprises. Know what you're paying for upfront with clear pricing and estimates.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                    </section>
                    <section className={style.section_handyman_signup_cta}>
                        <div className="container-fluid g-0">
                            <div className={"row " + style.d_flex_center }>
                                <div className="col-lg-5 col-sm-12 col-xs-12">
                                    <img src={plumber_reparing_pipe} alt="Plumber Jobs in Botswana." className={style.img_join_pro_network}></img>
                                </div>
                                <div className={"col-lg-5 col-sm-12 col-xs-12 " + style.text_content_join_pro_network}>
                                    <h3 className={style.header_join_pro_network}>Are you a Handyman?</h3>
                                    <p>Register with us and start earning.</p>
                                    <Link to="/hero-registration" className={style.btn_join_pro_network}>Join Pro Network</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className={style.faq_section}>
                        <h4 className={style.section_title}>Your Questions, Answered</h4>
                        <div className="container-fluid g-0">
                            <div className="row">
                                <div className="col-lg-5 col-sm-12 col-xs-12 col-md-12">
                                    <div id={style.rec_shape_blue}></div>
                                    <img src={plumber_botswana} alt="Plumbing Botswana HandyMan" className={style.plumber_img}></img>
                                </div>
                                <div className="col-lg-6 col-sm-12 col-md-12 mt-4">
                                    <CollapseText title="What is HomeHero?" body="HomeHero is a platform that connects homeowners with trusted service professionals for a wide range of home-related needs, from repairs and maintenance to renovations and improvements."></CollapseText>
                                    <CollapseText title="How does HomeHero connect Homeowners with Handyman?" body="We match home projects with the handyman in the same location."></CollapseText>
                                    <CollapseText title="How do I register as Handyman?" body="To register as a tradesman simply fill the form and provide us with a copy of your ID or passport to verify your account."></CollapseText>
                                    <CollapseText title="Can I get qoutation?" body="The handyman we connect you with will provide you with a qoutation for your home project."></CollapseText>
                                    <CollapseText title="How do I get a Handyman?" body="You can create a Project with us and we will give you a call with a Handyman that matches your home project."></CollapseText>
                                   
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    <section  className={style.section_cta} >
                        <div className="container-fluid g-0">
                            <div className={"row " + style.d_flex_center }>
                                <div className="col-lg-10">
                                    <div className={style.cta_content}>
                                        <h3 className={style.join_text}>Does your home need some saving?</h3>
                                        <p>Plumbers, Electricians, Carpenters and much more..</p>
                                        <Link to="/i-need-a-hero" className={style.create_service_request_btn_link}>Create Project</Link>
                                        <div id={style.rec_shape_yellow}></div>
                                        <img src={female_carpenter} alt="Find Handyman in Botswana." className={style.handyman_image}></img>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className={style.section_contact}>
                        <div className="container-fluid  g-0">
                            <div className={"row " + style.d_flex_center}>
                                <div className={"col-lg-8"}>
                                    <div className={"row "  + style.contact_call_to_action}>
                                        <div className="col-md-2 col-sm-12 col-xs-12">
                                            <i id={style.telephone_icon} className="bi bi-telephone"></i>
                                        </div>
                                        <div className={"col-lg-8 col-sm-12 col-xs-12"}>
                                            <h6>Do you have any inquiries, or would you like to speak over a phone call?</h6>
                                            <p>Call us for a quick chat <ButtonCall cellphone="73310406" label=""></ButtonCall></p>
                                            <p id={style.phone_number}>+(267) 73310406</p>
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                   
                    <Footer></Footer>
                </div>
            </>
        )
    }
}

export default WelcomePage