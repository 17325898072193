import React from "react";
import style from './collapse_text.module.css'

const CollapseText = ({title, body}) => {

    const [showBody,setShowBody] = React.useState(false)

    const toggleBody = () => {
        setTimeout(() => {
            setShowBody(!showBody)
        },500)
        
    
    } 

    const toggleCarretIcon = showBody ? <i className="bi bi-caret-up"></i> : <i className="bi bi-caret-down"></i>

    return(
        <div className={style.collapsable_item}>
            <div className={style.collapse_text_title} onClick={toggleBody}>
                {title}   {toggleCarretIcon}
            </div>
            {showBody && <div className={style.collapse_text_body}>
               <p>{body}</p> 
            </div>}
        </div>
    )

}

export default CollapseText;