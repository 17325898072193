import { BACKEND_URL } from "../config/api";

export const fetch_skills = async () => {
    var fetchUrl = BACKEND_URL + "/api/v1/skill/";

    const response = await fetch(fetchUrl);
    const data = await response.json();

    if(!response.ok) throw new Error(data.message || "Something went wrong");

    return data
    
}