import React from "react";
import NavBar from "../../partials/navbar/navbar";
import Footer from "../../partials/footer/footer";

const AboutUsPage = () =>{

    return(
        <>
            <NavBar></NavBar>
            <header>

            </header>
            <main>

            </main>
            <Footer></Footer>
        </>
    )
    
} 

export default AboutUsPage;