import React from "react";
import NavBar from "../../partials/navbar/navbar";
import Footer from "../../partials/footer/footer";
import smiling_woman_with_phone from "../../assets/images/png/smiling-woman-cellphone.png"
import style from "./homeowner_thank_you.module.css";
import { Link } from "react-router-dom";
import ButtonMailto from "../../components/button_mail_to/button_mail_to";

class HomeOwnerThankYouPage extends React.Component {


    render(){
        return(
            <>
                <div>
                    <NavBar></NavBar>
                    <section>
                    <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <img className={style.smiling_woman_with_phone} src={smiling_woman_with_phone} alt="Homeowners Maintenance Botswana"/>
                                </div>
                                <div className={"col-lg-6 col-md-6 col-sm-12 col-xs-12 " + style.thank_you_message}>
                                    <h6 className={style.h_6}>Thank you for your interest in our services. We will be in touch with you shortly.</h6>
                                    <Link to="/" className={style.btn_return}>Return to Home</Link>
                                    <p className={style.paragraph}>If you have any questions, <ButtonMailto label="please mail us." mailto="info@homehero.co.bw" /></p>
                                </div>
                            </div>

                        </div>
                    </section>
                    <Footer></Footer>
                </div>
            </>
        )
    }
}

export default HomeOwnerThankYouPage;