import React from "react";
import { Link } from "react-router-dom";
import style from './mobile_nav_menu.module.css';
import menu_icon  from "../../../assets/images/png/menu.png";
import close_icon  from "../../../assets/images/png/close_icon.png";
import logo from '../../../assets/images/png/homehero_125x125.png';


const MobileNavMenu = (props) =>
{
    const [showMobileMenu, setShowMobileMenu] = React.useState(false);

    const showToggleIcon =  showMobileMenu ? <img style={{ backgroundColor: 'rgb(239, 90, 115)'  }} src={close_icon} width={65} height={65} /> : <img style={{ backgroundColor: 'rgb(239, 90, 115,0.2)'  }} width={65} height={65} src={menu_icon} />;

    const toggleMenu = () =>{
        setShowMobileMenu(!showMobileMenu)
    }

    const mobile_content = showMobileMenu ? <ul className={style.menu}> 
            <li className={style.menu_item}><Link to="/how-it-works">How HomeHero Works</Link></li>
            <li className={style.menu_item}><Link to="/hero-registration">Get Free Job Leads</Link></li>
            <li className={style.menu_item}><Link to="/i-need-a-hero">Improve Your Home</Link></li>
            <li className={style.nav_menu_item}><Link to="https://den.homehero.co.bw">🦸🏾Hero's Login</Link></li>
        </ul> : null ;

    return(
        <div className={style.container}>
            <h4><Link to="/">HomeHero </Link></h4>
            <button type='button' className={style.btn_toggle} onClick={toggleMenu}>{showToggleIcon}</button>
            { mobile_content }
        </div>
    )
}

export default MobileNavMenu;