import React, { useState } from "react";
import style from './autocomplete.module.css'


const InputAutoComplete = (props) =>{

      /**
     * This component is used to create autocomplete suggestions from textinput
     */

    const [searchText,SetSearchText] = useState('')
    const [suggestions,SetSuggestions] = useState([])
    const [selected,SetSelected] = useState(null)
    const [showSuggestions,SetShowSuggestions] = useState(false)

    const onChange = (e) =>{
        const value = e.target.value;
        SetSearchText(value);
        SetShowSuggestions(true)
        SetSuggestions(props.suggestions.filter(suggestion => suggestion.title.toLowerCase().includes(value.toLowerCase())))
    }

    const onClick = (e) =>{
        SetSearchText(e.target.innerText)
        SetShowSuggestions(false)
        props.onSelected(e.target.innerText)
        SetSelected(null)
    }

    const displaySuggestions = suggestions.slice(0,4).map((suggestion,index) => <div key={index} onClick={onClick} className={style.suggestion}>{suggestion.title}</div>)

    return(
        <div className={style.autocomplete_container}>
            <input className={style.search_input} type="text" value={searchText} onChange={onChange} placeholder={props.placeholder}/>
            <div className={style.suggestions_container}>
                {showSuggestions ? displaySuggestions : null}
            </div>
        </div>
    )
}

export default InputAutoComplete;