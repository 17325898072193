import React from "react";
import style from './progress_bar.module.css';  // Make sure to import your CSS module

const ProgressBar = ({ step, totalSteps }) => {
    const progressBarWidth = (step / totalSteps) * 100;

    return (
        <div className={style.progress}>
            <div
                className={style.progressBar}
                style={{ width: `${progressBarWidth}%` }}
            />
        </div>
    );
};

export default ProgressBar;