import React from "react";
import PropTypes from "prop-types";
import style from "./testimonial.module.css";

const Testimonial = ({ body = "", clientName = "" }) => {
  return (
    <div className={style.testimonial}>
      <p className={style.testimonialBody}>{body}</p>
      <p className={style.clientName}>~ {clientName.toUpperCase()}</p>
    </div>
  );
};

Testimonial.propTypes = {
  body: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
};

export default Testimonial;
