import React, { useState } from "react";
import { Link } from "react-router-dom";
import style from './widescreen_nav_menu.module.css';
import logo from '../../../assets/images/png/homehero_125x125.png';
import MenuDropDown from "../menu-dropdown/menu_dropdown";

const WideScreenNavMenu = () => {

    const [showMoreMenu, setShowMoreMenu] = useState(false)

    let moreDropDownMenuItems = [
        {
            'linkTo' : '/how-it-works',
            'title': 'How HomeHero Works'
        },
        {
            'linkTo' : '/i-need-a-hero',
            'title': 'Improve Your Home'
        }
    ]

    return(
        <div className={style.container}>
                <div className={style.logo_container}>
                        <Link to="/"><img src={logo} alt="HomeHero Tradesman Botswana Jobs" className={style.logo} /></Link>
                </div>
                <div className={style.nav_menu}>
                    <ul> 
                        <li className={style.nav_menu_item}><Link to="/hero-registration" className={ style.btn_become_hero }>Become a hero</Link></li>
                        <li className={style.nav_menu_item}>
                            <MenuDropDown menuTitle="More" menuItems={moreDropDownMenuItems} />
                        </li>
                        <li className={style.nav_menu_item}><Link to="https://den.homehero.co.bw">🦸🏾Hero's Login</Link></li>
                    </ul>
                </div>

           </div> 
    )


}

export default WideScreenNavMenu