import React from 'react';
import FormFieldWarning from '../form-validations/form_field_warning';




export const Input = ({ id="", type="", label="", value, onChange, onBlur, className="" ,error,errorMessage="" }) => {
    return (
      <div>
        <label htmlFor={id}>{label}</label>
        <input id={id} type={type} value={value} className={className} onChange={onChange} onBlur={onBlur} />
        {error && <FormFieldWarning message={errorMessage} />}
      </div>
    );
};


export const FloatingInput = ({ id="", type="", label="",name="", value, onChange, onBlur, className="" ,error,errorMessage="" }) => {
    return (
        <>
            <div className='form-floating mb-3'>
                <input 
                    id={id} 
                    type={type} 
                    name={name}
                    value={value} 
                    className={className} 
                    onChange={e => onChange(e)} 
                    onBlur={onBlur} 
                    autoComplete='on' />
                <label htmlFor={id}>{label}</label>
            </div>
            {error && <FormFieldWarning message={errorMessage} />}
        </>
      
    );
};
