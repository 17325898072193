import { BACKEND_URL } from "../config/api";

export const fetch_task_types = async () => { 

    var fetchUrl = BACKEND_URL + "/api/v1/task-type/";

    const response = await fetch(fetchUrl);
    const data = await response.json();

    if(!response.ok) throw new Error(data.message || "Something went wrong");

    return data
    
}
