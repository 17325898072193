import { BACKEND_URL } from "../config/api";

export const fetch_cities = async () => {
    let fetchUrl = `${BACKEND_URL}/api/v1/city/`;

    const response = await fetch(fetchUrl);
    const data = await response.json();

    if(!response.ok) throw new Error(data.message || "Something went wrong");

    return data
    
}