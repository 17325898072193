import NavBar from "../../partials/navbar/navbar";
import { Link } from "react-router-dom";
import Footer from "../../partials/footer/footer";
import React from "react";
import style from "./how_it_works.module.css";
import vector_woman_using_laptop  from "../../assets/images/jpg/vector-woman-using-laptop.jpg";
import vector_woman_scheduling from "../../assets/images/png/vector-girl-scheduling.png";
import vector_family from "../../assets/images/png/vector-african-family.png"

class HowItWorksPage extends React.Component {


    render(){
        return(
            <>
                <div>
                    <NavBar></NavBar>
                    <header className={style.header}> 
                        <h1 className={style.header_text}>How-It-Works</h1>
                        <p className={style.sub_text}>Unveil HomeHero's Simple 3-Step Charm: Create, Connect, and Book with Ease.</p>
                        <span className={style.btn_start_project}><Link to="/i-need-a-hero">Start a Project</Link></span>
                    </header>
                    <section>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className={style.vector_container}>
                                        <img src={vector_woman_using_laptop} className={style.vector_img} alt="Find a Handyman in Botswana"/>
                                    </div>

                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className={style.step_container}>
                                        <h6>From Big To Small Projets</h6>
                                        <h3>1. Let us know about your Home's needs</h3>
                                        <p>Whether it's regular upkeep and fixes or the home of your dreams taking shape, we're here to assist with projects of all sizes.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className={style.vector_container}>
                                        <img src={vector_woman_scheduling} className={style.vector_img} alt="Looking for a Handyman in Botswana"/>
                                    </div>

                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div className={style.step_container}>
                                        <h6>Book With Confidence</h6>
                                        <h3>2. Get matched with a Hero and book an appointment</h3>
                                            <p>We match your project with a HomeHero in your area, you can then decide on a time, price and date to complete the work.</p>
                                    </div>
                                </div>
       
                            </div>
                            <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className={style.vector_container}>
                                            <img src={vector_family} className={style.vector_img} alt="Handyman in Botswana"/>
                                        </div>
                                    </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className={style.step_container}>
                                            <h6>Done</h6>
                                            <h3>3. Rate and Review</h3>
                                            <p>Rate and review the work completed by our Heroes, we have a 3 day  period where you can lodge a complaint if the work was not satisfactory..</p>
                                        </div>
                                    </div>
                                    
                            </div>
                        </div>
                    </section>
                    <Footer></Footer>
                </div>
            
            </>
        )
    }
}

export default HowItWorksPage;