import React, { useState } from "react";
import style from './menu_dropdown.module.css';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";



const MenuItem = ({linkTo, title}) =>{
    return <li role="presentation" className={style.menu_item}><Link className={style.link} to={linkTo}>{title}</Link></li>
}


/**
 * These menu items will be when button clicked
 * @param  param0 
 */
const MenuDropDown = ({menuTitle,menuItems}) => {

    const [isOpen,setIsOpen] = useState(false)

    const toggleMenu = () =>{
        setIsOpen(!isOpen) 
    }

    const menu = menuItems.map((item,index) =>(
        <>
           <MenuItem  key={index} linkTo={item.linkTo} title={item.title}></MenuItem>
        </>
    ));

    const showMenu = isOpen ? menu : null ;

    return(
        <>
            <span 
                className={style.menu_title} 
                onClick={toggleMenu}>
                    {menuTitle}
                    <i className="bi bi-caret-down"></i>
            </span>
            <ul 
                role="menu" 
                className={style.dropdown_menu}>
                { showMenu }
            </ul>
        </>
    )




}

MenuDropDown.propTypes = {
    menuTitle: PropTypes.string.isRequired,
    menuItems: PropTypes.arrayOf(PropTypes.object)
}

MenuItem.propTypes = {
    linkTo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
}

export default MenuDropDown;