import React, { useState, useEffect } from 'react';
import style from './multi_select.module.css';

const MultiSelectDropdown = (props) => {

    const [inputValue, setInputValue] = useState(''); // Track the input value
    const [selectedOption,setSelectedOption] = useState([])

  
    const inputChange = (event) =>{
        // Update the input value when the user types
        setInputValue(event.target.value);
    }

    useEffect(() => {
        props.onSelectedOptionsChange(selectedOption)
    }, [selectedOption]);


    const optionClick = (_id, value) =>{
        //gets the selected option and adds it to the selectedOption array
        if (selectedOption.length < 3) {
            let skill = {
                _id: _id,
                name: value
            }
            setSelectedOption([...selectedOption,skill]);
           
            //resets the input value
            setInputValue('');
        }
    }

    const removeSelectedOption = (value) =>{
         // Remove the selected option from the list
        setSelectedOption(selectedOption.filter((item) => item._id !== value));
       
    }

    const matchOptions = props.options.filter((item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    // Display the selected options
    const showSelectedItems =  selectedOption.map((item,index) => ( 
        <div key={index + item._id} className={style.selected_option}> 
            {item.name} <i onClick={() => removeSelectedOption(item._id)} className='bi bi-x-circle'></i> 
        </div>
    ));

    const optionsContainer = inputValue.length > 0 && selectedOption.length < 3 ? (
        <div className={style.options_container}>
            { matchOptions.map((item,index) => <div onClick={() => optionClick(item._id,item.name)} className={style.option} key={item._id} value={item._id}> {item.name} </div>)  } 
        </div>
    ) : null;

    return(
        <div id={props.id}  className={style.multi_select_container}>
            <div className={style.multi_select_input}>
                <input 
                    type='text' 
                    onChange={inputChange} 
                    value={inputValue} // Bind input value to state
                    name={props.name}
                    id="inputValue" 
                    className={style.input} 
                    placeholder="e.g plumbing" 
                />
            </div>
            {optionsContainer}
            <div className={style.selected_options_container}>
                { showSelectedItems } 
            </div>
            
        </div>
        
    )

}

export default MultiSelectDropdown
