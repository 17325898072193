import React from "react"
import style from './select.module.css'
import Option from "../option/option"

const Select = (props) => {

    const options = props.options.map((item,index) => {
        return (
            <Option key={`${index}-${item.name}`} title={item.name} value={item._id} />
        );
    
    });

    return (
        <select id={props.id} value={props.selectedOption} className={style.select} onChange={props.onChange} name={props.name}>
            <option value="-1" disabled key={'0'}>Please select option</option>
            {options}
        </select>
    );
} 

export default Select;