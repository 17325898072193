
export const botswana_cities = [
    {
        name: "-",
        value: "-1"
    },
    {
        name: "Gaborone",
        value: "Gaborone"
    },
    {
        name: "Francistown",
        value: "Francistown"
    },
    {
        name: "Molepolole",
        value: "Molepolole"
    },
    {
        name: "Mochudi",
        value: "Mochudi"
    },
    {
        name: "Maun",
        value: "Maun"
    },
    {
        name: "Kasane",
        value: "Kasane"
    },
    {
        name: "Serowe",
        value: "Serowe"
    },
    {
        name: "Palapye",
        value: "Palapye"
    },
    {
        name: "Mahalapye",
        value: "Mahalapye"
    },
    {
        name: "Ghanzi",
        value: "Ghanzi"
    },
    {
        name: "Selibe Phikwe",
        value: "Selibe Phikwe"
    },
    {
        name: "Bobonong",
        value: "Bobonong"
    },
    {
        name: "Jwaneng",
        value: "Jwaneng"
    },
    {
        name: "Orapa",
        value: "Orapa"
    },
    {
        name: "Kang",
        value: "Kang"
    },
    {
        name: "Kanye",
        value: "Kanye"
    },
    {
        name: "Mogoditshane",
        value: "Mogoditshane"
    },
    {
        name: "Tlokweng",
        value: "Tlokweng"
    },
    {
        name: "Lobatse",
        value: "Lobatse"
    },
    {
        name: "Mmopane",
        value: "Mmopane"
    },
    {
        name: "Gabane",
        value: "Gabane"
    },
    {
        name: "Otse",
        value: "Otse"
    },
    {
        name: "Bokaa",
        value: "Bokaa"
    },
    {
        name: "Artisia",
        value: "Artisia"
    },
    {
        name: "Oodi",
        value: "Oodi"
    },
    {
        name: "Phakalane",
        value: "Phakalane"
    },
    {
        name: "Tonota",
        value: "Tonota"
    },
    {
        name: "Thamaga",
        value: "Thamaga"
    },
    {
        name: "Ramotswa",
        value: "Ramotswa"
    },
    {
        name: "Letlhakeng",
        value: "Letlhakeng"
    },
    {
        name: "Moshupa",
        value: "Moshupa"
    },
    {
        name: "Metsimotlhabe",
        value: "Metsimotlhabe"
    },
    {
        name: "Kopong",
        value: "Kopong"
    },
    {
        name: "Goodhope",
        value: "Goodhope"
    },
    {
        name: "Rasesa",
        value: "Rasesa"
    },
    {
        name: "Tutume",
        value: "Tutume"
    },
    {
        name: "Hukuntsi",
        value: "Hukuntsi"
    },
    {
        name: "Manyana",
        value: "Manyana"
    }


]

export const trade_skills = [
    {
        name: "-",
        value: "-1"
    },
    {
        name: "Plumbing",
        value: "Plumbing"
    },
    {
        name: "Home Electrician",
        value: "Home Electrician"
    },
    {
        name: "Gardening",
        value: "Gardening"
    },
    {
        name: "Landscaping",
        value: "Landscaping"
    },
    {
        name: "Cleaning/Maid Work",
        value: "Cleaning/Maid Work"
    },
    {
        name: "Air Conditioning",
        value: "Air Conditioning"
    },
    {
        name: "Carpentry & Woodworking",
        value: "Carpentry"
    },
    {
        name: "Paving",
        value: "Paving"
    },
    {
        name: "Fencing",
        value: "Fencing"
    },
    {
        name: "Tiling",
        value: "Tiling"
    },
    {
        name: "Windows & Doors",
        value: "Windows & Doors"
    },
    {
        name: "Roofing",
        value: "Roofing"
    },
    {
        name: "Flooring & Hardwood",
        value: "Flooring & Hardwood"
    },
    {
        name: "Swimming Pools",
        value: "Swimming Pools"
    },
    {
        name: "Cellings",
        value: "Cellings"
    },
    {
        name: "TV & Dish Mouting",
        value: "TV & Dish Mouting"
    },
    {
        name: "Kitchen Appliances",
        value: "Kitchen Appliances"
    },
    {
        name: "Builder/ House Construction",
        value: "Builder/ House Construction"
    },
    {
        name: "Kitchen Cabinents",
        value: "Kitchen Cabinents"
    },
    {
        name: "Kitchen Cabinents",
        value: "Kitchen Cabinents"
    },
    {
        name: "Home Garage",
        value: "Home Garag"
    },
    {
        name: "Home Solar Energy",
        value: "Home Solar Energy"
    },
    {
        name: "Outlets, Panels, Switches & Wiring",
        value: "Outlets, Panels, Switches & Wiring"
    },
    {
        name: "Pest Control",
        value: "Pest Control"
    },
    {
        name: "Fireplaces, Gazebos, Braii Areas",
        value: "Fireplaces, Gazebos, Braii Areas"
    },
    {
        name: "Home Security",
        value: "Home Security"
    },
    {
        name: "Interior Design & Decorating",
        value: "Interior Design & Decorating"
    },
    {
        name: "Geyser Installation & Repair",
        value: "Geyser Installation & Repair"
    },
    {
        name: "Others",
        value: "Others"
    },
]


export const project_urgency = [
    {
        name: "-",
        value: "-1"
    },
    {
        name: "Immediately",
        value: "urgent"
    },
    {
        name: "This week",
        value: "important"
    },
    {
        name: "This month",
        value: "neutral"
    },
    {
        name: "In 2-3 Months",
        value: "low_priority"
    },
]