import React from "react"
import { createBrowserRouter, Outlet } from "react-router-dom"
import WelcomePage from "../pages/welcome/welcome_page";
import CreateProjectPage from "../pages/create_service_request/create_project_page";
import HeroRegistrationPage from "../pages/hero_registration/hero_registration";
import PrivacyPolicyPage from "../pages/privacy-policy/privacy_policy";
import HowItWorksPage from "../pages/how-it-works/how_it_works"
import TermsOfUsePage from "../pages/terms-of-use/terms_of_use";
import HeroThankYouPage from "../pages/hero-thank-you/hero_thank_you";
import HomeOwnerThankYouPage from "../pages/homeowner-thank-you/homeowner_thank_you";
import FindAHeroPage from "../pages/find-a-hero/find_a_hero";
import AboutUsPage from "../pages/about-us/about_us";

const router = createBrowserRouter([
    {
        path: '/',
        element:<Outlet></Outlet>,
        children:[
            {
                path: "/",
                element: <WelcomePage></WelcomePage>,
            },
            {
                path: "/find-a-hero",
                element: <FindAHeroPage></FindAHeroPage>
            },
            {
                path: "/i-need-a-hero",
                element: <CreateProjectPage></CreateProjectPage>
            },
            {
                path: "/hero-registration",
                element: <HeroRegistrationPage></HeroRegistrationPage>
            },
            {
                path: "/how-it-works",
                element: <HowItWorksPage></HowItWorksPage>
            },
            {
                path: "/hero-thank-you",
                element: <HeroThankYouPage/>
            },
            {
                path: "/homeowners-thank-you",
                element: <HomeOwnerThankYouPage />
            },
            {
                path: "/about-us",
                element: <AboutUsPage></AboutUsPage>
            },
            {
                path: "/privacy-policy",
                element: <PrivacyPolicyPage/>
            },
            {
                path: "/terms-of-use",
                element: <TermsOfUsePage/>
            }

        ]
    }
   
]);

export default router;