import React from 'react';
import style from './navbar.module.css';
import WideScreenNavMenu from './widescreen-nav-menu/widescreen_nav_menu';
import MobileNavMenu from './mobile-nav-menu/mobile_nav_menu';


const NavBar = () => { 

    return(
        <nav className={style.sticky}>
            <div className={style.nav_container}>
               <WideScreenNavMenu />
               <MobileNavMenu />
            </div>
        </nav>
    )

}

export default NavBar;