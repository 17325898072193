import React from "react"
import style from './footer.module.css'
import { Link } from "react-router-dom";
import microsoft_img from "../../assets/images/png/MS_Startups_Celebration_Badge_Light.png"

const Footer = () =>{
    const year = new Date().getFullYear();
    return(
        <footer className={style.footer}>
            <div className="container-fluid">
                <div className={"row " +  style.justify_content_between}>
                    <div className="col-md-4">
                        <img className={style.footer_affliation_img} src={microsoft_img} alt="Microsoft Startups Celebration Badge"></img>
                    </div>
                    <div className="col-md-4 mt-4">
                        <h4>Resources</h4>
                        <ul className={style.footer_ul}>
                            <li>Signing up as a Hero</li>
                            <li>Facebook Community</li>
                        </ul>

                    </div>
                    <div className="col-md-4 mt-4">
                        <h4>Other</h4>
                        <ul className={style.footer_ul}>
                            <li> <Link to={"/terms-of-use"}>Terms of use</Link></li>
                            <li><Link to={"/privacy-policy"}>Privacy Policy</Link></li>
                        </ul>


                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12" style={{textAlign: "center"}}>
                        <p>Copyright &copy; Acumetrics {year}</p>
                    </div>
                </div>
            </div>
        </footer>
    )
} 

export default Footer