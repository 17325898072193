import React, { useState,useRef } from "react"
import style from './searchbar.module.css'

const SearchBar = ({onSelectedOption, searchOptions}) => {

    const [searchText, setSearchText] = useState("");
    const [filteredOptions, setFilteredOptions] = useState([])
    const inputRef = useRef(null);


    const handleInputChange = (event) =>{
        var text = event.target.value;
        setSearchText(text)
        if(searchOptions !== undefined && searchOptions.length > 0){
            setFilteredOptions(searchOptions.filter((option) =>{
                return option.name.toLowerCase().includes(searchText.toLowerCase());
            }))

        }
    }

    const selectedOption = (name) =>{
        onSelectedOption(name)
        setSearchText(name)
        setFilteredOptions([])
    }

    return(
        <div className={style.search_skills_container}>
            <input ref={inputRef} 
                className={style.search_bar} 
                type="text"
                value={searchText}
                onChange={handleInputChange}
                placeholder="Plumbing"
            />
            <div className={style.search_options}>
                {
                    searchOptions.length === 0 && <div className={style.option_item}>No data available</div>
                }
                {filteredOptions.length !== undefined && filteredOptions.length > 0 && filteredOptions.map((option) =>{
                    return <div key={option._id} className={style.option_item} onClick={() => selectedOption(option.name)}>{option.name}</div>
                })}
            </div>
        </div>
    )
}

export default SearchBar;