import React from "react";
import { useState } from "react";
import style from "./find_a_hero.module.css"
import { botswana_cities, trade_skills } from '../../data/data_source';
import InputAutoComplete from "../../components/auto_complete/auto_complete";
import NavBar from "../../partials/navbar/navbar";
import Footer from "../../partials/footer/footer";
import { Link } from "react-router-dom";
import placeholder_img from "../../assets/images/webp/placeholder-image.webp"


const FindAHeroPage = () => {

    const [cardIsClicked, setCardIsClicked] = useState(null);
    const [searchService, setSearchService] = useState("");
    const [searchCity, setSearchCity] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const data = [
        {
            business_name: "Eazy A",
            location: "Maun",
            projects_completed: 2,
            skills: [
                "Builder/ House Construction", "Roofing"
            ],
            ratings: [
                
            ]
        },
        {
            business_name: "Pula Nkgodise (pty) ltd",
            location: "Gaborone",
            projects_completed: 2,
            skills: [
                "Home Electrician", "Applicance Installation"
            ],
            ratings: [
                {
                    client_id: 1,
                    rating: 3
                },
                {
                    client_id: 2,
                    rating: 2
                }
            ]
        },
        {
            business_name: "Pula Nkgodise (pty) ltd",
            location: "Gaborone",
            projects_completed: 2,
            skills: [
                "Gardening"
            ],
            ratings: [
                {
                    client_id: 1,
                    rating: 3
                },
                {
                    client_id: 2,
                    rating: 2
                }
            ]
        },
        {
            business_name: "Pula Nkgodise (pty) ltd",
            location: "Gaborone",
            projects_completed: 2,
            skills: [
                "Interior Design"
            ],
            ratings: [
                {
                    client_id: 1,
                    rating: 3
                },
                {
                    client_id: 2,
                    rating: 2
                }
            ]
        }
    ]


    const onSelectedServiceText = (service_name) =>{
        setSearchService(service_name)

    }

    const onSelectedCityText = (city_name) =>{
        setSearchCity(city_name)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(searchService)
        console.log(searchCity)
        //implement filter search here on the data
        const searchByCityFirst = searchCity.length > 0 ? data.filter((hero) => hero.location.toLowerCase() === searchCity.toLowerCase()) : data;
        const searchByService = searchService.length > 0 ? searchByCityFirst.filter((hero) => hero.skills.includes(searchService)) : searchByCityFirst;
        setSearchResults(searchByService);
    }

    const calculateAvgRating = (ratings = []) => {
        let total = 0;
        if (ratings.length === 0) {
            return 0;
        }
        ratings.forEach((rating) => {
            total += rating.rating;
        });
        return total / ratings.length;
    
    }

    const toggleProfileContent = (index) => {
       console.log("clicked", index)
       setCardIsClicked((prevIndex) => (prevIndex === index ? null : index));
    };

    const showHeroes = searchResults.length > 0 ? searchResults.map((hero, index) => (
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3 mt-3" key={index}>
            <div className={style.hero_profile} >
                <div className={style.hero_profile_picture}>
                    <img className={style.hero_profile_picture} src={placeholder_img} alt={hero.business_name}></img>
                </div>
                <div className={style.hero_profile_header}>
                        <h4 className={style.hero_business_name}>{hero.business_name}</h4>
                        <span className={style.hero_profile_rating}><i className="bi bi-star"></i> {calculateAvgRating(hero.ratings)}</span>
                </div>
                <div className={style.hero_dropdown_icon}>
                    <i className="bi bi-arrow-down-circle" onClick={() =>toggleProfileContent(index)}></i>
                </div>
                {cardIsClicked === index ?
                      <div className={style.hero_profile_content}>
                        <div className={style.hero_location}>
                            <span><i className="bi bi-geo-alt-fill"></i></span>
                            <span className={style.location_text}>{hero.location}</span>
                        </div>
                        <div className={style.hero_job_hires}>
                            <span><i className="bi bi-trophy"></i></span>
                            <span className={style.hero_job_count}>{hero.projects_completed} Hires</span>
                        </div>
                        
                        <hr className={style.profile_break_line}></hr>
                        <div className={style.hero_profile_footer}>
                            <h6><Link className={style.hero_get_qoute}><i className="bi bi-chat"></i> Request Qoute</Link></h6>
                            <Link className={style.btn_view_hero} to="/">See More</Link>
                        </div>
                    </div>
                :<p></p>
                }
            
              
            </div>
        </div>
        
    )) : <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3 mt-3"></div>;

    return(
        <>
            <div>
                <NavBar></NavBar>
                <header>

                </header>
                <section>
                    <div className="container-fluid g-0">
                        <div className={"row " + style.search_row}>
                            <div className="col-lg-12">
                               <form className={style.search_form}  onSubmit={handleSubmit}>
                                    <div className="container-fluid g-0">
                                        <div className="row">
                                            <div className={"col-lg-12 " + style.search_container}>
                                                <InputAutoComplete searchText={searchService}  suggestions={trade_skills} onSelected={onSelectedServiceText} placeholder="Service"/>
                                                <InputAutoComplete searchText={searchCity} suggestions={botswana_cities} onSelected={onSelectedCityText} placeholder="Location"/>
                                                <button className={style.btn_search}><i className="bi bi-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className={"col-lg-12 " + style.search_results_text}>
                               {searchResults.length > 0 ? <h6>Found {searchResults.length} {searchService} Hero in {searchCity}</h6> : <h6>Type something to search..</h6>  } 
                            </div>
                        </div>
                        <div className="row mt-5">
                            {showHeroes}
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        </>
    )

    
}

export default FindAHeroPage;