import React from "react";
import style from './create_project_page.module.css';
import { Link } from "react-router-dom";
import { project_urgency } from '../../data/data_source';
import Select from "../../components/select/select";
import Footer from "../../partials/footer/footer";
import FormFieldWarning from "../../components/form-validations/form_field_warning";
import { LoaderDotted } from "../../components/loader/loader";
import { withNavigation } from "../../hooks/with_navigation";
import ButtonCall from "../../components/button_call/button_call";
import ProgressBar from "../../components/progress-bar/progress_bar";
import Testimonial from "../../components/testimonial/testimonial";
import NavBar from "../../partials/navbar/navbar";
import { fetch_cities } from '../../services/city_service';
import { fetch_task_types } from '../../services/task_type_service';
import { createTask } from "../../services/task_service";
import SearchBar from "../../components/search_bar/searchbar";
import vector_relax from "../../assets/images/jpg/sit_n_relax.jpg"


class CreateProjectPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            firstname: '',
            lastname: '',
            cellphone: '',
            email: '',
            location: '',
            home_address: '',
            project_urgency: '',
            task_type: '',
            estimated_budget: 0,
            description: '',
            cities: [],
            task_types: [],
            step: 0,
            firstname_error: false,
            lastname_error: false,
            cellphone_error: false,
            location_error: false,
            description_error: false,
            is_loading: false,
            submit_response: null,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    onChange = (e) => {
        var value = e.target.value;
        var element_name = e.target.name;
        console.log(value)
        this.setState({ [element_name] : value})
        this.setState({ submit_response: null})
        this.setState({ location_error: false})

    }

    handleInputChange = (e) => {
        var value = e.target.value;
        var element_name = e.target.name;
        this.setState({
            [element_name]: value
        })
        this.setState({ firstname_error: false})
        this.setState({ lastname_error: false})
        this.setState({ cellphone_error: false})
        this.setState({ description_error: false})
        
    }

    handleNext = () => {
        if(this.state.step === 1){
            if (this.state.description.length === 0) {
                this.setState({ description_error: true })
                return
            }

        }
        if(this.state.step === 2){
            if(this.state.location.length === 0){
                this.setState({ location_error: true })
                return
            }

        }
        const step_count = this.state.step + 1
        this.setState({ step: step_count })
    };
    
    handlePrevious = () => {
        const step_count = this.state.step - 1
        this.setState({ step: step_count })
    };

    handleSubmit = (e) => {
        e.preventDefault();
        

        if (this.state.description.length === 0) {
            this.setState({ description_error: true })
            this.setState({ submit_response: "You forgot to include your work description."})
            this.setState({step: 1})
            return
        }

        if(this.state.location.length === 0){
            this.setState({ location_error: true })
            this.setState({ submit_response: "You forgot to select your location."})
            this.setState({step: 2})
            return
        }

        const botswanaPhoneNumberPattern = /^7\d{7}$/;
            
        if (this.state.firstname.length === 0) {
                this.setState({ firstname_error: true })
                return
        }
    
        if (this.state.lastname.length === 0) {
            this.setState({ lastname_error: true })
            return
        }

        if(!this.state.cellphone.match(botswanaPhoneNumberPattern)){
            this.setState({ cellphone_error: true })
            return
        }

        this.setState({ is_loading: true })
        
        const data = {
            first_name: this.state.firstname,
            last_name: this.state.lastname,
            phone_number: this.state.cellphone,
            email: this.state.email,
            location: this.state.location,
            task_type: this.state.task_type,
            home_address: this.state.home_address,
            project_urgency: this.state.project_urgency,
            estimated_budget: this.state.estimated_budget,
            description: this.state.description
        }

        createTask(data).then((res) => {
            this.setState({ is_loading: false })
            
            const step_count = this.state.step + 1
            setTimeout(() =>{
                this.setState({ submit_response: "Thanks for sharing your Task with us, we will get back to you soon." })
                this.setState({ step: step_count })
            },1500);
        }).catch((err) =>{
            console.error(err);
            this.setState({ is_loading: false })
            
            setTimeout(() =>{
                this.setState({ submit_response: "Oops!, something went wrong, please try again later." })
                this.setState({ step: 0 })
            },1500);
        });
       

        
    } 

    
    componentDidMount(){
     
        fetch_cities().then(response => {
            this.setState({ cities: response})
        }).catch(error => {
            console.error(error)

        });

        fetch_task_types().then(response => {
            this.setState({ task_types: response})
        }).catch(error => {
            console.error(error)
        });

    } 

    onSelectedTaskType = (task_type) =>{ 
        this.setState({task_type: task_type});
    }


    render(){
        return(
            <div>
                <NavBar></NavBar>
                <header className={style.header}> 
                        <h1 className={style.header_text}>Home Care Made Easy</h1>
                        <p className={style.sub_text}>Get connected to trusted and verified Pro's in your area.</p>
                </header>
                <section>
                    <div className="container-fluid g-0">
                        <div className="col-lg-12">
                            <div className="row mb-4">
                                <div className={"col-md-10 " + style.text_center}>
                                    <h2 className={style.header_h2}>How it Works</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3">
                                    <i className={"bi bi-body-text " + style.how_it_works_icon}></i>
                                    <h5 className="mb-2 mt-2">1. Describe what your home needs</h5>
                                    <p className="mt-4">Whether it's regular upkeep and fixes or the home of your dreams taking shape, we're here to assist with projects of all sizes.</p>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3">
                                    <i className={"bi bi-circle-square "  + style.how_it_works_icon}></i>
                                    <h5 className="mb-2 mt-2">2.Get Matched</h5>
                                    <p className="mt-4">We match your project with a HomeHero in your area, you can then decide on a time, price and date to complete the work.</p>
                                </div>
                                <div className="col-md-4 col-md-4 col-sm-12 col-xs-12 mb-3">
                                    <i className={"bi bi-calendar-check "  + style.how_it_works_icon}></i>
                                    <h5 className="mb-2 mt-2">3. Book and Relax</h5>
                                    <p className="mt-4">Once matched we will inform you about Handyman in your area that can get the job done. You choose.</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container-fluid g-0">
                        <div className="col-lg-12">
                            <form className={style.form}  onSubmit={this.handleSubmit}>
                                <div className="container-fluid" style={{boxShadow:"0px 4px 4px 0px rgba(0, 0, 0, 0.25)", paddingBottom: "2rem"}}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <ProgressBar step={this.state.step}  totalSteps={4}/>
                                    </div>
                                    <div className={"col-md-12 " + style.error_message + " " + style.d_flex_center}>
                                        <p> {this.state.submit_response ?  this.state.submit_response : null }</p>
                                    </div>
                                </div>
                                {
                                    this.state.step === 0 && (
                                        <div>
                                            <div className="row mt-4">
                                                <div className="col-md-12">
                                                    <h3 className={style.step_title}>Get Started</h3>
                                                    <p style={{ textAlign: "center", marginTop: "1rem" }}>Tell us what your home needs.</p>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-md-12">
                                                     <SearchBar onSelectedOption={this.onSelectedTaskType} searchOptions={this.state.task_types}></SearchBar>
                                                </div>
                                            </div>
                                        </div>
                                )}
                                {   this.state.step === 1 && (
                                    <div>
                                
                                        <div className={"row mt-4 " + style.d_flex_center}>
                                            <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                                                <div className="mb-3">
                                                    <textarea className="form-control" placeholder={"Please describe your task"} 
                                                    style={{ resize: 'none' }}
                                                    value={this.state.description}
                                                    name="description"
                                                    rows="4"
                                                    cols="50"
                                                    maxLength="500"
                                                    minLength="10"
                                                    id="description" 
                                                    onChange={this.handleInputChange}></textarea>
                                                     { this.state.description_error && <FormFieldWarning message="This is required" />  }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"row mt-4 " + style.d_flex_center}>
                                            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                                <label htmlFor="estimated_budget">When do you want it done?</label>
                                                <Select options={project_urgency} onChange={this.onChange} name="project_urgency" />
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                                <div className="form-floating" style={{ marginTop: '2.9rem' }}>
                                                    <input id="estimated_budget" name="estimated_budget" className="form-control form-control-sm" type="number" onChange={this.handleInputChange} />
                                                    <label htmlFor="estimated_budget">Estimated Budget (BWP)</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {   this.state.step === 2 && (
                                    <div>
                                        <div className="row mt-4">
                                            <div className="col-md-12">
                                                <h3 className={style.step_title}>Where do you stay</h3>
                                                <p style={{ textAlign: "center", marginTop: "1rem" }}>This is so we can find the nearest Handyman to contact you.</p>
                                            </div>
                                        </div>
                                        <div className={"row mt-4 " + style.d_flex_center}>
                                            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                                <label htmlFor="location">Location</label>
                                                <Select options={this.state.cities} onChange={this.onChange} name="location" />
                                                { this.state.location_error && <FormFieldWarning message="Please select your location." />  }
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                                <div className={"form-floating"} style={{ marginTop: '2.9rem' }}>
                                                    <input id="home_address" name="home_address" className="form-control form-control-sm" type="text" placeholder="Gaborone Plot xxxx" onChange={this.handleInputChange} />
                                                    <label htmlFor="home_address">Home Address(Optional)</label>
                                                </div>
                                                <small>We will not share your address without your consent.</small>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {   this.state.step === 3 && (
                                    <div> 
                                        <div className="row mt-4">
                                            <div className="col-md-12">
                                                <h3 className={style.step_title}>Provide Contact Information</h3>
                                                <p style={{ textAlign: "center", marginTop: "1rem" }}>How should our heroes contact you.</p>
                                            </div>
                                        </div>
                                        <div className={"row mt-4 " + style.d_flex_center}>
                                            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                                <div className="form-floating mb-3">
                                                    <input id="firstname" name="firstname" className="form-control form-control-sm" type="text" placeholder="First Name" onChange={this.handleInputChange} />
                                                    <label htmlFor="firstname">Firstname</label>
                                                </div>
                                                { this.state.firstname_error && <FormFieldWarning message="Firstname is required" />  }
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                                <div className="form-floating mb-3">
                                                    <input id="lastname" name="lastname" className="form-control" type="text" placeholder="Last name" onChange={this.handleInputChange} />
                                                    <label htmlFor="lastname">Lastname</label>
                                                </div>
                                                { this.state.lastname_error && <FormFieldWarning message="Lastname is required" />  }
                                            </div>
                                        </div>
                                        <div className={"row mt-4 " + style.d_flex_center}>
                                            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                                <div className="form-floating mb-3">
                                                    <input id="cellphone" name="cellphone" className="form-control form-control-sm" type="text" placeholder="(267) 71000000" onChange={this.handleInputChange} />
                                                    <label htmlFor="cellphone">Cellphone</label>
                                                </div>
                                                <small>We use this to connect you to HomeOwners.</small>
                                                { this.state.cellphone_error && <FormFieldWarning message="We only accept BW phone numbers 🥲" />  }
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                                <div className="form-floating mb-3">
                                                    <input id="email" name="email" className="form-control form-control-sm" type="email" placeholder="johndoe@mail.com" onChange={this.handleInputChange} />
                                                    <label htmlFor="cellphone">Email (Optional)</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                )}
                                 {   this.state.step === 4 && (
                                    <div className={"row mt-4 " + style.d_flex_center}>
                                        <div className={"col-md-6 "}>
                                            <h3>Time to sit and relax, our team will be in contact with you soon.</h3>
                                            <div className={style.vector_container}>
                                                <img src={vector_relax} className={style.vector_relax_img} alt="Handyman in Botswana"/>
                                            </div>
                                        </div>                             
                                    </div>
                                    
                                 )}
                                    <div className={"row mt-4 " + style.d_flex_center}>
                                            
                                            <div className={"col-md-10 " + (this.state.step >= 1 ? style.d_flex_space_between : style.d_flex_center)}>
                                                {this.state.step === 0 &&  (
                                                     <button type="button" className={style.btn_submit} onClick={this.handleNext}>Next</button>
                                                )}
                                                
                                                {this.state.step > 0 &&  this.state.step < 4 && (
                                                    <button type="button" className={style.btn_submit} onClick={this.handlePrevious}>Back</button>
                                                )}
                                                {this.state.step > 0 && this.state.step < 3 &&  (
                                                    <button type="button" className={style.btn_submit} onClick={this.handleNext}>Next</button>
                                                )}

                                                {this.state.step === 3 &&  (
                                                    this.state.is_loading ? <LoaderDotted /> : <button className={style.btn_submit} type="button" onClick={this.handleSubmit}>Get me a Hero</button>
                                                )}

                                            </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                <section className={style.section_contact}>
                    <div className="container-fluid  g-0">
                        <div className={"row " + style.d_flex_center}>
                            <div className={"col-lg-8"}>
                                <div className={"row "  + style.contact_call_to_action}>
                                    <div className="col-md-2 col-sm-12 col-xs-12">
                                        <i id={style.telephone_icon} className="bi bi-telephone"></i>
                                    </div>
                                    <div className={"col-lg-8 col-sm-12 col-xs-12"}>
                                        <h6>Do you have any inquiries, or would you like to speak over a phone call?</h6>
                                        <p>Call us for a quick chat <ButtonCall cellphone="73310406" label=""></ButtonCall></p>
                                        <p id={style.phone_number}>+(267) 73410406</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container-fluid">
                        <div className="row">
                            <div className={"col-md-12 " + style.text_center}>
                                <h4 className={style.header_h4}>What Homeowners Are Saying</h4>
                            </div>
                        </div>
                        <div className={"row mt-4 " + style.d_flex_center}>
                            <div className="col-md-10">
                                <Testimonial body="I needed someone to assemble some very complicated patio furniture, 
                                I was connected to JD Crafts and we negotiated a good price. He was profesional and got the job done." clientName="Kabelo"></Testimonial>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        )
    }
    
}

export default withNavigation(CreateProjectPage);