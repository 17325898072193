import React from "react";
import style from './hero_registration.module.css'
import Select from "../../components/select/select";
import MultiSelectDropdown from "../../components/multi_select/multi_select";
import Footer from "../../partials/footer/footer";
import {FloatingInput} from "../../components/input/input";
import FormFieldWarning from "../../components/form-validations/form_field_warning";
import { LoaderDotted } from "../../components/loader/loader";
import { withNavigation } from "../../hooks/with_navigation";
import ButtonCall from "../../components/button_call/button_call";
import ProgressBar from "../../components/progress-bar/progress_bar";
import NavBar from "../../partials/navbar/navbar";
import { fetch_skills } from '../../services/skills_servive';
import { fetch_cities } from '../../services/city_service';
import { storeUser }  from '../../services/user_service';
import { storeUserProfile, storeHandyman } from '../../services/handyman_service';

class HeroRegistrationPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            firstname: '',
            lastname: '',
            cellphone: '',
            email: '',
            location: '-1',
            password: '',
            passwordConfirmation: '',
            skills : [],
            cities: [],
            selectedSkills: [],
            step: 1,
            isLoading: false,
            submitResponse: '',
            firstnameError: false,
            lastnameError: false,
            passwordError: false,
            passwordConfirmationError: false,
            cellphoneError: false,
            locationError: false,
            selectedSkillError: false,
            
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateSelectedSkills = this.updateSelectedSkills.bind(this);
    }

    onChange = (e) => {
        var value = e.target.value;
        var element_name = e.target.name;
        this.setState({ [element_name] : value})
        this.setState({ submitResponse: null})
        this.setState({ locationError: false})

    }

    updateSelectedSkills = (arrOfSkills) =>{
       this.setState({selectedSkills: arrOfSkills})
    }

    handleInputChange = (e) => {
        let value = e.target.value;
        let element_name = e.target.name;
        this.setState({
            [element_name]: value
        })
        this.setState({ firstnameError: false})
        this.setState({ lastnameError: false})
        this.setState({ cellphoneError: false})
        this.setState({ passwordError: false})
        this.setState({ passwordConfirmationError: false})
    }
    
    componentDidUpdate(prevProps, prevState){
        if(prevState.step !== this.state.step){
            this.setState({ submitResponse: null})
        }
    
    }

    handleNext = () => {
        
        if(this.state.step === 1){
            if(this.state.selectedSkills.length === 0){
                this.setState({ selectedSkillError: true })
                return
            }
            if(this.state.location.length === 0){
                this.setState({locationError: true })
                return
            }
        }

        if(this.state.step === 2){
            const botswanaPhoneNumberPattern = /^7\d{7}$/;
            if (this.state.firstname.length === 0) {
                this.setState({ firstnameError: true })
                return
            }

            if (this.state.lastname.length === 0) {
                this.setState({ lastnameError: true })
                return
            }

            if(!this.state.cellphone.match(botswanaPhoneNumberPattern)){
                this.setState({ cellphoneError: true })
                return
            }
        }
        const step_count = this.state.step + 1
        this.setState({ step: step_count })
    };

    handlePrevious = () => {
        const step_count = this.state.step - 1
        this.setState({ step: step_count })
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ isLoading: true })

        if(this.state.password.length <= 5){
            this.setState({ passwordError: true })
            return
        }
        
        if(this.state.password !== this.state.passwordConfirmation){
            this.setState({ passwordConfirmationError: true })
            return
        }

        const createAuthCredentials = async() =>{
            await storeUser(this.state.cellphone,this.state.password)
            .then(res =>{
                let insertedId = res.inserted_id
                if (insertedId === undefined) {
                    this.setState({ submitResponse: "Oops!! somethwing went wrong, please try again." })
                    this.setState({ isLoading: false })
                    return
                }
                createUserProfile(insertedId)
            }).catch(error => {
                this.setState({ isLoading: false })
                this.setState({ submitResponse: "Oops!! somethwing went wrong, please try again." })
            })
        }
        
        const createUserProfile =  async (userId='') =>{
            const userProfile = {
                user_id: userId,  
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                email: this.state.email,
                location: this.state.location,  
            }
            await storeUserProfile(userProfile)
            .then(res =>{
                createHandyManProfile(userId,res.inserted_id)
            }).catch(error => {
                this.setState({ isLoading: false })
                this.setState({ submitResponse: "Oops!! somethwing went wrong, please try again." })
            })
        } 

        const createHandyManProfile = async(user_id, userProfileId='') =>{
            const arrayOfSkillIds = this.state.selectedSkills.map(skill => skill._id)
            const handymanProfile = {
                user_id: user_id,
                profile_id: userProfileId,
                city_id: this.state.location,
                skills: arrayOfSkillIds
            }
            console.log(handymanProfile)
            await storeHandyman(handymanProfile)
            .then(res =>{
                this.setState({ submitResponse: "Registration Successful" })
                setTimeout(() => {
                    // 👇️ redirects to an external URL
                    window.location.replace('https://den.homehero.co.bw');
                }, 1300);
                this.setState({ isLoading: false })
            }).catch(error => {
                this.setState({ isLoading: false })
                this.setState({ submitResponse: "Oops, something went wrong, please try again." })
            })
        }

        createAuthCredentials()
        
    };  


    componentDidMount(){
        fetch_skills().then(response => {
            this.setState({ skills: response})
            
        }).catch(error => {
            console.error(error)
        })

        fetch_cities().then(response => {
            this.setState({ cities: response})
        }).catch(error => {
            console.error(error)

        })
    }


    render(){
        return(
            <div>
                <NavBar></NavBar>
                <header className={style.header}> 
                        <h1 className={style.header_text}>Get Free Job Leads</h1>
                        <p className={style.sub_text}>Join us and get free jobs straight to your phone.</p>
                </header>
                <section>
                    <div className="container-fluid g-0">
                        <div className="col-lg-12">
                            
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3">
                                    <i className={"bi bi-phone " + style.handyman_value_proposition_icon }></i>
                                    <h5 className="mb-2 mt-2">Work For Yourself</h5>
                                    <p className="mt-4">You control the types of home projects you want to do, and you can set your schedule. You keep 100% of what you earn, plus tips!</p>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3">
                                    <i className={"bi bi-bell " + style.handyman_value_proposition_icon}></i>
                                    <h5 className="mb-2 mt-2">Set Your Job Notifications</h5>
                                    <p className="mt-4">Choose how you want to recieve job leads, whether through email or texts to your phone.</p>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3">
                                    <i className={"bi bi-graph-up-arrow " + style.handyman_value_proposition_icon}></i>
                                    <h5 className="mb-2 mt-2">Grow Your Business</h5>
                                    <p className="mt-4">Homehero is made with love in Botswana with the help with Handyman like yourself and some tech wizards.Use us to help grow and expand your business.</p>
                                </div>
    
                            </div>
                        </div>
                    </div>
                    
                </section>
                <section>
                    <div className="container-fluid g-0">
                        <div className="row">
                            <div className="col-lg-12">
                                <form className={style.form}  onSubmit={this.handleSubmit}>
                                    <div className="container-fluid" style={{boxShadow:"0px 4px 4px 0px rgba(0, 0, 0, 0.25)", paddingBottom: "2rem"}}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <ProgressBar step={this.state.step}  totalSteps={3}/>
                                            </div>
                                            <div className={"col-md-12 " + style.error_message + " " + style.d_flex_center}>
                                                <p> {this.state.submitResponse ?  this.state.submitResponse : null }</p>
                                            </div>
                                        </div>
                                    {   this.state.step === 1 && (
                                        <div>
                                            <div className="row mt-4">
                                                <div className="col-md-12">
                                                    <h3 className={style.step_title}>Be Your Own Boss</h3>
                                                    <p style={{ textAlign: "center", marginTop: "1rem" }}>We are here to help you expand your business and get more leads.</p>
                                                </div>
                                            </div>
                                            <div className={"row mt-4 " + style.d_flex_center}>
                                                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                                    <label id="skills" className="mb-4">What services do you provide</label>
                                                    <MultiSelectDropdown id="skills" options={this.state.skills} name="trade_skills" onSelectedOptionsChange={this.updateSelectedSkills} />
                                                    <small>We are going to send you projects matching these trade skills.</small>
                                                    { this.state.selectedSkillError && <FormFieldWarning message="Please select one or more  services." />  }
                                                </div>
                                            </div>
                                            <div className={"row mt-4 " + style.d_flex_center}>
                                                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                                    <label htmlFor="location">Location</label>
                                                    <Select id="location" selectedOption={this.state.location} options={this.state.cities} onChange={this.onChange} name="location" />
                                                    <small>We are going to send you projects based on this location.</small>
                                                    { this.state.locationError && <FormFieldWarning message="Please select your location." />  }
                                                </div>
                                            </div>
                                        </div>
                                     )}
                                    {   this.state.step === 2 && (
                                        <div>
                                            <div className="row mt-4">
                                                <div className="col-md-12">
                                                    <h3 className={style.step_title}>Provide Contact Information For Homeowners.</h3>
                                                    <p style={{ textAlign: "center", marginTop: "1rem" }}>Help Homeowners get in contact with you.</p>
                                                </div>
                                            </div>
                                            <div className={"row mt-4 " + style.d_flex_center}>
                                                <div className="col-lg-5 col-md-5 col-sm-10 col-xs-10">
                                                    <div className="form-floating mb-3">
                                                        <FloatingInput 
                                                            id="firstname" 
                                                            label="firstname"
                                                            name="firstname" 
                                                            className="form-control form-control-sm" 
                                                            type="text" 
                                                            placeholder="John" 
                                                            onChange={this.handleInputChange} 
                                                            value={this.state.firstname}
                                                            error={this.state.firstnameError}
                                                            errorMessage="Firstname is required"
                                                        />    
                                                    </div>
                                                   

                                                </div>
                                                <div className="col-lg-5 col-md-5 col-sm-10 col-xs-10">
                                                    <div className="form-floating mb-3">
                                                        <FloatingInput 
                                                            id="lastname"
                                                            label="lastname" 
                                                            name="lastname" 
                                                            className="form-control" 
                                                            type="text" 
                                                            placeholder="Doe" 
                                                            onChange={this.handleInputChange}
                                                            value={this.state.lastname}
                                                            error={this.state.lastnameError}
                                                            errorMessage="Lastname is required"
                                                        />
                                                      
                                                    </div>
                                                   
                                                </div>
                                            </div>                                 
                                            <div className={"row mt-4 " + style.d_flex_center}>
                                                <div className="col-lg-5 col-md-5 col-sm-10 col-xs-10">
                                                    <div className="form-floating mb-3">
                                                        <FloatingInput 
                                                            id="cellphone" 
                                                            label="cellphone"
                                                            name="cellphone" 
                                                            className="form-control form-control-sm" 
                                                            type="text" 
                                                            placeholder="(+267) 76710242" 
                                                            onChange={this.handleInputChange}
                                                            value={this.state.cellphone}
                                                            error={this.state.cellphoneError}
                                                            errorMessage="This service is only available for Botswana phone numbers."
                                                        />
                                                    </div>
                                                    <small>We use this to connect you to HomeOwners.</small>
                                                </div>
                                                <div className="col-lg-5 col-md-5 col-sm-10 col-xs-10">
                                                    <div className="form-floating mb-3">
                                                        <FloatingInput 
                                                            id="email"
                                                            label="email" 
                                                            name="email" 
                                                            className="form-control form-control-sm" 
                                                            type="email" 
                                                            placeholder="johndoe@mail.com"
                                                            value={this.state.email} 
                                                            onChange={this.handleInputChange} 
                                                         />
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            
                                        </div>
                                        
                                     )} 
                                     {   this.state.step === 3 && (
                                        <div>   
                                            <div className="row mt-4">
                                                <div className="col-md-12">
                                                    <h3 className={style.step_title}>Secure Your Account.</h3>
                                                    <p style={{ textAlign: "center", marginTop: "1rem" }}>Provide a password for your account..</p>
                                                </div>
                                            </div>
                                            <div className={"row mt-4 " + style.d_flex_center}>
                                                <div className="col-lg-5 col-md-5 col-sm-10 col-xs-10">
                                                    <div className="form-floating mb-3">
                                                        <input id="password" name="password" className="form-control form-control-sm" type="password" placeholder="********" onChange={this.handleInputChange} />
                                                        <label htmlFor="password">Password</label>
                                                    </div>
                                                    { this.state.passwordError && <FormFieldWarning message="Your password should have at least 6 characters and contains at least one symbol" />  }
                                                </div>
                                                <div className="col-lg-5 col-md-5 col-sm-10 col-xs-10">
                                                    <div className="form-floating mb-3">
                                                        <input id="passwordConfirmation" name="passwordConfirmation" className="form-control form-control-sm" type="password" placeholder="********" onChange={this.handleInputChange} />
                                                        <label htmlFor="passwordConfirmation">Confirm password</label>
                                                    </div>
                                                    { this.state.passwordConfirmationError && <FormFieldWarning message="Passwords do not match" />  }
                                                </div>
                                            </div>

                                        </div>
                                     )}
                                    
                                        <div className={"row mt-4 " + style.d_flex_center}>
                                            
                                            <div className={"col-md-10 " + (this.state.step >= 2 ? style.d_flex_space_between : style.d_flex_center)}>
                                                
                                                {this.state.step > 1 &&  (
                                                    <button type="button" className={style.btn_submit} onClick={this.handlePrevious}>Previous</button>
                                                )}
                                                {this.state.step === 1 &&  (
                                                     <button type="button" className={style.btn_submit} onClick={this.handleNext}>Lets Go</button>
                                                )}
                                                {this.state.step > 1 && this.state.step < 3 &&  (
                                                    <button type="button" className={style.btn_submit} onClick={this.handleNext}>Next</button>
                                                )}

                                                {this.state.step === 3 &&  (
                                                    this.state.isLoading ? <LoaderDotted /> : <button className={style.btn_submit} type="button" onClick={this.handleSubmit}>Send me Jobs</button>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className={style.section_contact}>
                    <div className="container-fluid  g-0">
                        <div className={"row " + style.d_flex_center}>
                            <div className={"col-lg-8"}>
                                <div className={"row "  + style.contact_call_to_action}>
                                    <div className="col-md-2 col-sm-12 col-xs-12">
                                        <i id={style.telephone_icon} className="bi bi-telephone"></i>
                                    </div>
                                    <div className={"col-lg-8 col-sm-12 col-xs-12"}>
                                        <h6>Do you have any inquiries, or would you like to speak over a phone call?</h6>
                                        <p>Call us for a quick chat <ButtonCall cellphone="73310406" label=""></ButtonCall></p>
                                        <p id={style.phone_number}>+(267) 73410406</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>  
            </div>
        )
    }
    
}

export default withNavigation(HeroRegistrationPage);